import React from "react"
import styled from 'styled-components'
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"


const PostText = styled.p`
  font-size: 1.3rem;
  color: black;
  line-height: 1.5rem;
`;

export default function BlogTemplate({ data, pageContext }) {
  const post = data.markdownRemark
  const { previous, next } = pageContext
  return (
    <Layout>
    <SEO  title={post.frontmatter.title} />
    <article>
          <header>
            <h2
              style={{
                marginTop: 1,
                marginBottom: 0,
              }}
            >
              {post.frontmatter.title}
            </h2>
            <p
              style={{
                display: `block`,
                marginBottom: 1,
                fontSize: `1rem`,
              }}
            >
              {post.frontmatter.date}
            </p>
          </header>
          <section>
            <PostText
              dangerouslySetInnerHTML={{ __html: post.html }}/>
          </section>
          <hr
            style={{
              marginBottom: 1,
            }}
          />
        </article>

        <nav>
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 100,
            }}
          >
            {/* <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li> */}
          </ul>
        </nav>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`